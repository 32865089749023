@font-face {
  font-family: ti-font;

  /* increment jjnsvy<i> when updating the set, also in error.ejs and server config (src/server/config) */
  src: url('/assets/fonts/ti-font.eot?jjnsvy3');
  src:
    url('/assets/fonts/ti-font.eot?jjnsvy3#iefix') format('embedded-opentype'),
    url('/assets/fonts/ti-font.woff2?jjnsvy3') format('woff2'),
    url('/assets/fonts/ti-font.ttf?jjnsvy3') format('truetype'),
    url('/assets/fonts/ti-font.woff?jjnsvy3') format('woff'),
    url('/assets/fonts/ti-font.svg?jjnsvy3#ti-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class*='icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: ti-font, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alarm::before {
  content: '\e902';
}

.icon-alert::before {
  content: '\e903';
}

.icon-align-center::before {
  content: '\e904';
}

.icon-align-justify::before {
  content: '\e905';
}

.icon-align-left::before {
  content: '\e906';
}

.icon-align-right::before {
  content: '\e907';
}

.icon-anchor::before {
  content: '\e908';
}

.icon-apple::before {
  content: '\e909';
}

.icon-archive::before {
  content: '\e90a';
}

.icon-arrow-down::before {
  content: '\e90b';
}

.icon-arrow-left::before {
  content: '\e90c';
}

.icon-arrow-right::before {
  content: '\e90d';
}

.icon-arrow-thin-down::before {
  content: '\e90e';
}

.icon-arrow-thin-left::before {
  content: '\e90f';
}

.icon-arrow-thin-right::before {
  content: '\e910';
}

.icon-arrow-thin-up::before {
  content: '\e911';
}

.icon-arrow-up::before {
  content: '\e912';
}

.icon-article::before {
  content: '\e913';
}

.icon-audiobook::before {
  content: '\e914';
}

.icon-backspace::before {
  content: '\e915';
}

.icon-baseball::before {
  content: '\e916';
}

.icon-basket::before {
  content: '\e917';
}

.icon-basketball::before {
  content: '\e918';
}

.icon-battery-empty::before {
  content: '\e919';
}

.icon-battery-full::before {
  content: '\e91a';
}

.icon-battery-low::before {
  content: '\e91b';
}

.icon-battery-medium::before {
  content: '\e91c';
}

.icon-bell::before {
  content: '\e91d';
}

.icon-blog::before {
  content: '\e91e';
}

.icon-bluetooth::before {
  content: '\e91f';
}

.icon-bold::before {
  content: '\e920';
}

.icon-book::before {
  content: '\e921';
}

.icon-bookmark::before {
  content: '\e922';
}

.icon-bookmarks::before {
  content: '\e923';
}

.icon-box::before {
  content: '\e924';
}

.icon-bridge::before {
  content: '\e925';
}

.icon-briefcase::before {
  content: '\e926';
}

.icon-brightness-low::before {
  content: '\e927';
}

.icon-brightness-max::before {
  content: '\e928';
}

.icon-brightness-medium::before {
  content: '\e929';
}

.icon-broadcast::before {
  content: '\e92a';
}

.icon-browser::before {
  content: '\e92c';
}

.icon-browser-upload::before {
  content: '\e92b';
}

.icon-brush::before {
  content: '\e92d';
}

.icon-calendar::before {
  content: '\e92e';
}

.icon-camcorder::before {
  content: '\e92f';
}

.icon-camera::before {
  content: '\e930';
}

.icon-card::before {
  content: '\e931';
}

.icon-cart::before {
  content: '\e932';
}

.icon-chat::before {
  content: '\e933';
}

.icon-checklist::before {
  content: '\e934';
}

.icon-checkmark::before {
  content: '\e935';
}

.icon-chevron-down::before {
  content: '\e936';
}

.icon-chevron-left::before {
  content: '\e937';
}

.icon-chevron-right::before {
  content: '\e938';
}

.icon-chevron-up::before {
  content: '\e939';
}

.icon-circle-check::before {
  content: '\e93a';
}

.icon-clipboard::before {
  content: '\e93b';
}

.icon-clock::before {
  content: '\e93c';
}

.icon-clockwise::before {
  content: '\e9e4';
}

.icon-cloud::before {
  content: '\e93f';
}

.icon-cloud-download::before {
  content: '\e93d';
}

.icon-cloud-upload::before {
  content: '\e93e';
}

.icon-code::before {
  content: '\e940';
}

.icon-compass::before {
  content: '\e941';
}

.icon-contract::before {
  content: '\e943';
}

.icon-contract-2::before {
  content: '\e942';
}

.icon-conversation::before {
  content: '\e944';
}

.icon-copy::before {
  content: '\e945';
}

.icon-crop::before {
  content: '\e946';
}

.icon-cross::before {
  content: '\e947';
}

.icon-crosshair::before {
  content: '\e948';
}

.icon-cutlery::before {
  content: '\e949';
}

.icon-device-desktop::before {
  content: '\e94a';
}

.icon-device-mobile::before {
  content: '\e94b';
}

.icon-device-tablet::before {
  content: '\e94c';
}

.icon-direction::before {
  content: '\e94d';
}

.icon-disc::before {
  content: '\e94e';
}

.icon-document::before {
  content: '\e953';
}

.icon-document-delete::before {
  content: '\e94f';
}

.icon-document-edit::before {
  content: '\e950';
}

.icon-document-new::before {
  content: '\e951';
}

.icon-document-remove::before {
  content: '\e952';
}

.icon-dog::before {
  content: '\e954';
}

.icon-dot::before {
  content: '\e955';
}

.icon-dots-2::before {
  content: '\e956';
}

.icon-dots-3::before {
  content: '\e957';
}

.icon-download::before {
  content: '\e958';
}

.icon-duplicate::before {
  content: '\e959';
}

.icon-enter::before {
  content: '\e95a';
}

.icon-exit::before {
  content: '\e95b';
}

.icon-expand::before {
  content: '\e95d';
}

.icon-expand-2::before {
  content: '\e95c';
}

.icon-experiment::before {
  content: '\e95e';
}

.icon-export::before {
  content: '\e960';
}

.icon-facebook::before {
  content: '\e961';
}

.icon-feed::before {
  content: '\e962';
}

.icon-flag::before {
  content: '\e963';
}

.icon-flashlight::before {
  content: '\e964';
}

.icon-folder::before {
  content: '\e967';
}

.icon-folder-open::before {
  content: '\e965';
}

.icon-folder-solid::before {
  content: '\e966';
}

.icon-forward::before {
  content: '\e968';
}

.icon-gaming::before {
  content: '\e969';
}

.icon-google-play::before {
  content: '\e96a';
}

.icon-google-plus::before {
  content: '\e96b';
}

.icon-graduation::before {
  content: '\e96c';
}

.icon-graph-bar::before {
  content: '\e96d';
}

.icon-graph-line::before {
  content: '\e96e';
}

.icon-graph-pie::before {
  content: '\e96f';
}

.icon-headphones-solid::before {
  content: '\e970';
}

.icon-headset::before {
  content: '\e9e5';
}

.icon-heart-empty::before {
  content: '\e971';
}

.icon-heart-full::before {
  content: '\e972';
}

.icon-help::before {
  content: '\e973';
}

.icon-home::before {
  content: '\e974';
}

.icon-hourglass::before {
  content: '\e975';
}

.icon-inbox::before {
  content: '\e976';
}

.icon-information::before {
  content: '\e977';
}

.icon-instagram::before {
  content: '\e978';
}

.icon-italic::before {
  content: '\e979';
}

.icon-jewel::before {
  content: '\e97a';
}

.icon-lifting::before {
  content: '\e97b';
}

.icon-lightbulb::before {
  content: '\e97c';
}

.icon-link::before {
  content: '\e97e';
}

.icon-link-broken::before {
  content: '\e97d';
}

.icon-linkedin::before {
  content: '\e97f';
}

.icon-list::before {
  content: '\e980';
}

.icon-loading::before {
  content: '\e981';
}

.icon-location::before {
  content: '\e983';
}

.icon-location-solid::before {
  content: '\e982';
}

.icon-lock::before {
  content: '\e984';
}

.icon-mail::before {
  content: '\e985';
}

.icon-map::before {
  content: '\e986';
}

.icon-media-loop::before {
  content: '\e9e6';
}

.icon-media-next::before {
  content: '\e9e7';
}

.icon-media-pause::before {
  content: '\e9e8';
}

.icon-media-play::before {
  content: '\e9e9';
}

.icon-media-previous::before {
  content: '\e9ea';
}

.icon-media-record::before {
  content: '\e9eb';
}

.icon-media-shuffle::before {
  content: '\e9ec';
}

.icon-media-stop::before {
  content: '\e9ed';
}

.icon-medical::before {
  content: '\e987';
}

.icon-menu::before {
  content: '\e988';
}

.icon-message::before {
  content: '\e989';
}

.icon-meter::before {
  content: '\e98a';
}

.icon-microphone::before {
  content: '\e9ee';
}

.icon-minus::before {
  content: '\e98b';
}

.icon-monitor::before {
  content: '\e98c';
}

.icon-move::before {
  content: '\e98d';
}

.icon-music::before {
  content: '\e9ef';
}

.icon-music-solid::before {
  content: '\e98e';
}

.icon-network-1::before {
  content: '\e98f';
}

.icon-network-2::before {
  content: '\e990';
}

.icon-network-3::before {
  content: '\e991';
}

.icon-network-4::before {
  content: '\e992';
}

.icon-network-5::before {
  content: '\e993';
}

.icon-newspaper::before {
  content: '\e994';
}

.icon-pamphlet::before {
  content: '\e995';
}

.icon-paperclip::before {
  content: '\e996';
}

.icon-paperplane::before {
  content: '\e997';
}

.icon-pause-circle-outline::before {
  content: '\e998';
}

.icon-pause-circle-solid::before {
  content: '\e999';
}

.icon-pencil::before {
  content: '\e99a';
}

.icon-phone::before {
  content: '\e99b';
}

.icon-photo::before {
  content: '\e99d';
}

.icon-photo-group::before {
  content: '\e99c';
}

.icon-piggybank::before {
  content: '\e99e';
}

.icon-pill::before {
  content: '\e99f';
}

.icon-pin::before {
  content: '\e9a0';
}

.icon-play-circle-outline::before {
  content: '\e9a1';
}

.icon-play-circle-solid::before {
  content: '\e9a2';
}

.icon-playback::before {
  content: '\e9a3';
}

.icon-player-popout::before {
  content: '\e9a4';
}

.icon-plus::before {
  content: '\e9a5';
}

.icon-popout::before {
  content: '\e9a6';
}

.icon-popout-circle::before {
  content: '\e9f6';
}

.icon-power::before {
  content: '\e9a7';
}

.icon-premium::before {
  content: '\e9a9';
}

.icon-premium-solid::before {
  content: '\e9a8';
}

.icon-preview::before {
  content: '\e9aa';
}

.icon-print::before {
  content: '\e9ab';
}

.icon-pulse::before {
  content: '\e9ac';
}

.icon-question::before {
  content: '\e9ad';
}

.icon-recordings::before {
  content: '\e9ae';
}

.icon-reply::before {
  content: '\e9b0';
}

.icon-reply-all::before {
  content: '\e9af';
}

.icon-return::before {
  content: '\e9f0';
}

.icon-retweet::before {
  content: '\e9f1';
}

.icon-rocket::before {
  content: '\e9b1';
}

.icon-scale::before {
  content: '\e9b2';
}

.icon-scroll-left::before {
  content: '\e9b3';
}

.icon-scroll-right::before {
  content: '\e9b4';
}

.icon-search::before {
  content: '\e9b5';
}

.icon-sec-ff::before {
  content: '\e900';
}

.icon-sec-rw::before {
  content: '\e901';
}

.icon-share::before {
  content: '\e95f';
}

.icon-shopping-bag::before {
  content: '\e9b6';
}

.icon-skip::before {
  content: '\e9b7';
}

.icon-sports::before {
  content: '\e9b8';
}

.icon-stack::before {
  content: '\e9b9';
}

.icon-star::before {
  content: '\e9ba';
}

.icon-stop-circle-outline::before {
  content: '\e9f7';
}

.icon-stop-circle-solid::before {
  content: '\e9f8';
}

.icon-stopwatch::before {
  content: '\e9bb';
}

.icon-store::before {
  content: '\e9bc';
}

.icon-suitcase::before {
  content: '\e9bd';
}

.icon-swap::before {
  content: '\e9be';
}

.icon-tag::before {
  content: '\e9c0';
}

.icon-tag-delete::before {
  content: '\e9bf';
}

.icon-tags::before {
  content: '\e9c1';
}

.icon-thumbs-down::before {
  content: '\e9c2';
}

.icon-thumbs-up::before {
  content: '\e9c3';
}

.icon-ticket::before {
  content: '\e9c4';
}

.icon-time-reverse::before {
  content: '\e9c5';
}

.icon-to-do::before {
  content: '\e9c6';
}

.icon-toggles::before {
  content: '\e9c7';
}

.icon-trash::before {
  content: '\e9c9';
}

.icon-trash-solid::before {
  content: '\e9c8';
}

.icon-tumblr::before {
  content: '\e9ca';
}

.icon-tunein-bug::before {
  content: '\e9cb';
}

.icon-tunein-logo::before {
  content: '\e9cc';
}

.icon-twitter::before {
  content: '\e9cd';
}

.icon-umbrella::before {
  content: '\e9ce';
}

.icon-upload::before {
  content: '\e9cf';
}

.icon-user::before {
  content: '\e9d4';
}

.icon-user-group::before {
  content: '\e9d0';
}

.icon-user-id::before {
  content: '\e9d1';
}

.icon-user-private::before {
  content: '\e9d2';
}

.icon-user-solid::before {
  content: '\e9d3';
}

.icon-vibrate::before {
  content: '\e9d5';
}

.icon-view-apps::before {
  content: '\e9d6';
}

.icon-view-list::before {
  content: '\e9d8';
}

.icon-view-list-large::before {
  content: '\e9d7';
}

.icon-view-thumb::before {
  content: '\e9d9';
}

.icon-volume-full::before {
  content: '\e9f2';
}

.icon-volume-low::before {
  content: '\e9f3';
}

.icon-volume-medium::before {
  content: '\e9f4';
}

.icon-volume-off::before {
  content: '\e9f5';
}

.icon-wallet::before {
  content: '\e9da';
}

.icon-warning::before {
  content: '\e9dc';
}

.icon-warning-solid::before {
  content: '\e9db';
}

.icon-web::before {
  content: '\e9dd';
}

.icon-weight::before {
  content: '\e9de';
}

.icon-wifi::before {
  content: '\e9df';
}

.icon-windows::before {
  content: '\e9e0';
}

.icon-wrong::before {
  content: '\e9e1';
}

.icon-zoom-in::before {
  content: '\e9e2';
}

.icon-zoom-out::before {
  content: '\e9e3';
}
