/*
  Default for all links:
  - disable underlines
*/
.link {
  text-decoration: none;
  transition: var(--base-transition);

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
  }
}

/* Default definition for links with the teal color */
.greenLink {
  composes: link;
  color: var(--primary-color-1);

  &:hover,
  &:focus {
    color: var(--cloud-grey);
  }
}

/* Default definition for links with the ink color */
.inkLink {
  composes: link;
  color: var(--secondary-color-5);

  [data-darkmode='true'] & {
    color: var(--white);
  }

  &.titleContainer {
    display: flex;
    flex-direction: column;
  }

  &:hover,
  &:focus {
    color: var(--cloud-grey);
  }
}

.errorStateBorder {
  &[data-error='true'] {
    border: 1px solid var(--error-color);
  }
}

.errorStateLabel {
  &[data-error='true'] {
    color: var(--error-color);
  }
}

.field {
  composes: errorStateBorder;
  width: 100%;
  height: var(--form-item-height-px);
  border: 1px solid var(--border-color);
  border-radius: var(--form-item-border-radius-px);
  background-color: white;
  color: var(--secondary-color-1);
  font-size: var(--form-item-font-size-px);
  transition: var(--base-transition);

  &:focus {
    outline: none;
    border: 1px solid var(--secondary-color-4);
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.verticalAlignChild {
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
}

.verticalDivider {
  margin-right: var(--field-margin);
  margin-left: var(--field-margin);
  width: 1px;
  height: 20px;
  background-color: var(--divider);
}

.contentContainer {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--content-side-padding-px-small);
  padding-right: var(--content-side-padding-px-small);
  max-width: var(--content-max-width-px);
  padding-bottom: var(--content-area-spacing);
  overflow-x: hidden;

  @media (--min-width-small) {
    padding-left: var(--content-side-left-padding-px-med);
    padding-right: var(--content-side-padding-right-scrollbar-px-med);
  }

  @media (--min-width-xlarge) {
    padding-right: var(--content-side-right-padding-px-med);
  }

  /*
    adds spacing between top of page and first child container in content container
  */
  & > *:first-child {
    margin-top: var(--content-area-spacing-first-child);
  }

  /*
    allows container items content to sit flush with the top of the page
    when other content is hidden (e.g., title, display ads, etc.)
  */
  .containerItemsWrapper {
    margin-top: 0 !important;
  }

  :global(html.discord) & {
    padding: var(--discord-content-top-padding) var(--discord-content-y-padding)
      var(--discord-content-bottom-padding) var(--discord-content-y-padding);
  }

  :global(html.discord.infoBannerVisible) & {
    padding-top: calc(
      var(--discord-content-top-padding) - var(--safe-area-inset-top)
    );
  }

  :global(html.ford) & {
    padding-top: var(--discord-content-top-padding);
    padding-bottom: var(--discord-content-bottom-padding);
    padding-left: 0;
    padding-right: 0;
  }
}

.clearfix {
  display: table;
  content: '';
  clear: both;
}

.overlay {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
  transition: var(--base-transition);

  &:hover {
    opacity: 0.8;
  }
}

.screenReaderOnly {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
}
